import { createPublicClient, fallback, webSocket, http, Hex, ClientConfig } from "viem";
import { syncToZustand } from "@latticexyz/store-sync/zustand";
import { getNetworkConfig } from "./getNetworkConfig";
import { Subject, share } from "rxjs";
import { ContractWrite } from "@latticexyz/common";

import mudConfig from "contracts/mud.config";

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;

export async function setupNetwork() {
  const networkConfig = getNetworkConfig();

  const clientOptions = {
    chain: networkConfig.chain,
    transport: fallback([webSocket(), http()]),
    pollingInterval: 100,
    cacheTime: 100,
  } as const satisfies ClientConfig;

  const publicClient = createPublicClient(clientOptions);

  /*
   * Create an observable for contract writes that we can
   * pass into MUD dev tools for tx observability.
   */
  const write$ = new Subject<ContractWrite>();

  /*
   * Sync on-chain state into RECS and keeps our client in sync.
   * Uses the MUD indexer if available, otherwise falls back
   * to the viem publicClient to make RPC calls to fetch MUD
   * events from the chain.
   */
  const { tables, useStore, latestBlock$, storedBlockLogs$, waitForTransaction } =
    await syncToZustand({
      // For some reason when importing mudConfig in Node.js, instead of just
      // being the object, it's wrapped in an object with a `default` property.
      config: "default" in mudConfig ? (mudConfig.default as typeof mudConfig) : mudConfig,
      address: networkConfig.worldAddress as Hex,
      publicClient,
      startBlock: BigInt(networkConfig.initialBlockNumber),
    });

  return {
    ...networkConfig,
    tables,
    useStore,
    publicClient,
    latestBlock$,
    storedBlockLogs$,
    waitForTransaction,
    write$: write$.asObservable().pipe(share()),
  };
}
