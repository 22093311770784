import { BigintMinHeap } from "./bigintMinHeap";
import { GameState, GameConfig, LineState } from "./game/configLib";
import { Entity } from "./game/entityLib";
import { processCollisions } from "./game/lineLib";
import { heapifyPacked } from "./pq96x160";
import { timeWad } from "./timeLib";

// curried
export const parseSyncStateGivenTables = (tables: any) => (state: any) => {
  if (state.syncProgress.step !== "live")
    return {
      lastSyncedTime: 0,
      syncProgress: state.syncProgress,
      data: undefined,
    };

  const [rawGameState, gameConfig] = [
    state.getValue(tables.GameState, {}) as GameState,
    state.getValue(tables.GameConfig, {}) as GameConfig,
  ];

  const highScores = new Map<bigint, BigintMinHeap>(); // Will be put into GameState.
  Object.values(state.getRecords(tables.Player)).forEach((record: any) => {
    highScores.set(record.key.entityId, record.value.highScores);
  });

  const usernames = new Map<bigint, string | undefined>(); // Will be put into GameState.
  Object.values(state.getRecords(tables.UsernameOffchain)).forEach((record: any) => {
    usernames.set(record.key.entityId, record.value.username);
  });

  // Many tables share the same key schema, so we'll merge them by entityId for simplicity.
  const flatEntities: Entity[] = Object.values(state.getRecords(tables.Entity)).map(
    (record: any) => {
      const entityId = record.key.entityId;

      return {
        entityId,
        etype: record.value.etype,
        mass: record.value.mass,
        velMultiplier: record.value.velMultiplier,
        lineId: record.value.lineId,
        lastX: record.value.lastX,
        lastTouchedTime: record.value.lastTouchedTime,
        leftNeighbor: record.value.leftNeighbor,
        rightNeighbor: record.value.rightNeighbor,
        // This is not actually in this table, but it's simpler to just put it in here:
        lastConsumedPowerPelletTime:
          state.getValue(tables.Player, { entityId })?.lastConsumedPowerPelletTime ?? 0n,
        consumedMass: state.getValue(tables.Player, { entityId })?.consumedMass ?? 0n,
      };
    }
  );

  // Reshape flat entities into a multi-dimensional array, where each sub-array is a lineId.
  const lines = flatEntities.reduce((acc, record) => {
    if (!acc[record.lineId]) acc[record.lineId] = [];

    acc[record.lineId].push(record);
    return acc;
  }, [] as Entity[][]);

  const lineStates = Object.values(state.getRecords(tables.Line))
    .sort((a: any, b: any) => a.key.lineId - b.key.lineId) // This is crucial for the order of lines to be consistent!
    .map((q: any) => ({
      ...q.value,
      collisionQueue: heapifyPacked(q.value.collisionQueue),
      // This is not actually in this table, but it's simpler to just put it in here:
      lastTouchedTime:
        state.getValue(tables.LineOffchain, { lineId: q.key.lineId })?.lastTouchedTime ?? 0n,
    })) as LineState[];

  const lastSyncedTime = performance.now();

  return {
    lastSyncedTime: lastSyncedTime,
    syncProgress: state.syncProgress,
    data: {
      gameState: {
        ...rawGameState,
        highScores, // Add highScores to GameState.
        usernames, // Add usernames to GameState.
      },
      gameConfig,
      lines,
      lineStates,
    },
  };
};

export type LiveState = {
  lastSyncedTime: number;
  lastProcessedTime: bigint;
  lines: Entity[][];
  lineStates: LineState[];
  gameState: GameState;
};

export function forwardStateTo(
  prevState: LiveState,
  gameConfig: GameConfig,
  playSfx: boolean,
  playerIdForSfx: bigint | null,
  options: { stopAtIteration: number | null; stopAtTimestampWad: bigint | null }
): LiveState {
  const { stopAtIteration, stopAtTimestampWad } = options;

  let newState = structuredClone(prevState);

  let lastCollisionTime = -1n; // For debugging.
  for (let i = 0; i < newState.lines.length; i++) {
    lastCollisionTime = processCollisions(
      newState.lines[i],
      newState.gameState,
      gameConfig,
      newState.lineStates[i],
      playSfx,
      playerIdForSfx,
      {
        stopAtIteration,
        stopAtTimestampWad,
      }
    );
  }

  return {
    ...newState,
    lastProcessedTime:
      stopAtIteration == null ? stopAtTimestampWad ?? timeWad() : lastCollisionTime,
  };
}
