import React from "react";
import ReactDOM from "react-dom/client";

import { ChakraProvider } from "@chakra-ui/react";

import { App } from "./App";

import { setup } from "./mud/setup";
import { MUDProvider } from "./MUDContext";

import { CHAIN_ID } from "./mud/getNetworkConfig";

// import mudConfig from "contracts/mud.config";

import "./styles.css";

import { WagmiProvider, createConfig } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { supportedChains } from "./mud/supportedChains";
import { createClient, fallback, http, webSocket } from "viem";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { AccountKitProvider } from "@latticexyz/account-kit";
import { getNetworkConfig } from "./mud/getNetworkConfig";

import "./utils/polyfills";
import "@rainbow-me/rainbowkit/styles.css";

// ----------------------------------------  LOGROCKET
import LogRocket from "logrocket";
import { CHAIN_TIMESTAMP_OFFSET_MS, formatOffset } from "./utils/timeLib";
console.log("🚀 Starting LogRocket...");
LogRocket.init("paradigm/rethmatch");

const queryClient = new QueryClient();

const wagmiConfig = createConfig({
  chains: supportedChains,
  client: ({ chain }) =>
    createClient({
      chain,
      transport: fallback([webSocket(), http()]),
      pollingInterval: 100,
      cacheTime: 100,
    }),
});

const networkConfig = getNetworkConfig();

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");
const root = ReactDOM.createRoot(rootElement);

setup().then(async (result) => {
  async function syncClockOffset() {
    const computeClockOffset = async () => {
      const t0 = performance.now(); // ≈ timestamp of request packet transmission.
      // @ts-ignore
      let { current_wall_time_ms, last_block_timestamp, last_block_wall_time_ms } =
        await result.network.publicClient.request({
          // @ts-ignore
          method: "odyssey_getWallTimeData",
        });

      const chainoffset = last_block_timestamp * 1000 - last_block_wall_time_ms;
      const chaintime = current_wall_time_ms + chainoffset;

      // We use performance.now() to measure round trip time because it's more accurate
      // than Date.now() and not impacted by user clock adjustments, clock skew, etc.
      const [roundTrip, clienttime] = [performance.now() - t0, Date.now()];

      // See: https://en.wikipedia.org/wiki/Network_Time_Protocol#Clock_synchronization_algorithm
      // serverClockTime is in seconds, clientClockTime/roundTrip is in milliseconds, so we convert.
      const clockOffset = chaintime - clienttime + roundTrip / 2;

      console.log("Net clock offset:", formatOffset(clockOffset));
      console.log("-> Chain offset:", formatOffset(chainoffset));
      console.log("-> System offset:", formatOffset(clockOffset - chainoffset));
      console.log("Timeserver round trip:", roundTrip.toFixed(2) + "ms");

      return { clockOffset, roundTrip };
    };

    if (Number(CHAIN_ID) === 31337) {
      console.log("Using local node, setting clock offset to 0.");
      CHAIN_TIMESTAMP_OFFSET_MS.value = 0;
    } else {
      try {
        for (let attempts = 0; attempts < 5; attempts++) {
          const { clockOffset, roundTrip } = await computeClockOffset();
          // If the round trip was sub 150ms, we'll accept the measurement.
          if (roundTrip < 150) {
            CHAIN_TIMESTAMP_OFFSET_MS.value = Math.floor(clockOffset);
            return;
          }
          // If it was longer, we'll try again, hopefully was just a transient issue.
          console.log("Round trip took dangerously long, retrying...");
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        // If we've reached this point, the round trip to the timeserver consistently took
        // more than 150ms. This level of latency makes it difficult to trust the accuracy of
        // the clock offset sync. A bad sync can make the game unplayable. We'll alert the user.
        console.warn("Network has really high latency, alerting user...");
        alert(`🕖 Your network has significant latency (>150ms).

  👾 This will heavily degrade game performance and/or cause glitches.

  🛜 Try switching networks, disabling any VPNs, and/or restarting your computer.

  🔄 Refresh the page to try again.`);
      } catch (error) {
        console.error("Clock offset sync failed:", error);
      }
    }
  }

  setTimeout(syncClockOffset, 500); // Run once quickly at the start to set the offset.
  setTimeout(syncClockOffset, 15000); // Run again after 15s since initial reading is often bad.
  setInterval(syncClockOffset, 1 * 60 * 1000); // Run again every 1 minute(s) to deal with any skew.

  root.render(
    <ChakraProvider>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={darkTheme({ borderRadius: "none" })}
            initialChain={networkConfig.chain}
          >
            <AccountKitProvider
              config={{
                chainId: networkConfig.chain.id,
                worldAddress: networkConfig.worldAddress,
                erc4337: false,
                theme: "dark",
              }}
            >
              <MUDProvider value={result}>
                <App />
              </MUDProvider>
            </AccountKitProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  );

  // // https://vitejs.dev/guide/env-and-mode.html
  // if (import.meta.env.DEV) {
  //   const { mount: mountDevTools } = await import("@latticexyz/dev-tools");
  //   mountDevTools({
  //     config: mudConfig,
  //     publicClient: result.network.publicClient,
  //     walletClient: result.network.walletClient,
  //     latestBlock$: result.network.latestBlock$,
  //     storedBlockLogs$: result.network.storedBlockLogs$,
  //     worldAddress: result.network.worldContract.address,
  //     worldAbi: result.network.worldContract.abi,
  //     write$: result.network.write$,
  //     useStore: result.network.useStore,
  //   });
  // }
});
