import { useState } from "react";
import { useRef } from "react";
import { expectedNextBlockTimestamp } from "./blockTimestamp";
import { useToast } from "@chakra-ui/react";
import { WAD } from "./bigint";
import { chainTime } from "./timeLib";

export function useOnchainButton(
  action: (
    stopLoadingEarly: () => void,
    expectedNextBlockTimestampArray: [number, bigint]
  ) => Promise<void>
) {
  const toast = useToast();

  const [expectedInclusionTime, setExpectedInclusionTime] = useState<number | null>(null);

  const isLoadingRef = useRef(false);

  const onClick = async () => {
    if (isLoadingRef.current) {
      // Already loading, don't allow calling again.
      console.log("Still loading... ignoring button press.");
      return;
    }

    isLoadingRef.current = true;

    let [nbt, nbtWad] = expectedNextBlockTimestamp();

    const CLOSE_CALL_THRESHOLD = 125; // ms until the expected inclusion time

    // If we're really close to the expected next block, then we won't have time to
    // show an animation and things will look jittery. Solve for this by sleeping
    // for a bit so we can get into the next block, and then have ample time for
    // an animation. This means some percent of moves will have to wait >1s, but
    // it's a tradeoff we're (currently) willing to make to optimize the UI feel.
    if (nbt * 1000 - chainTime() < CLOSE_CALL_THRESHOLD) {
      const SLEEP_TIME = CLOSE_CALL_THRESHOLD * 1.4;
      console.log(
        `Within ${CLOSE_CALL_THRESHOLD}ms of expected inclusion time, going to sleep for ${SLEEP_TIME.toFixed(0)}ms and aim for the next block`
      );
      nbt += 1;
      nbtWad += WAD;
      setExpectedInclusionTime(nbt);
      await new Promise((resolve) => setTimeout(resolve, SLEEP_TIME));
    } else {
      setExpectedInclusionTime(nbt);
    }

    try {
      await action(() => {
        setExpectedInclusionTime(null);
      }, [nbt, nbtWad]);
    } catch (e: any) {
      toast({
        title: "Transaction reverted.",
        description: e.toString().startsWith("Error:") ? e.toString().substring(6) : e.toString(),
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
        containerStyle: {
          width: "325px",
          maxWidth: "100%",
        },
      });
    } finally {
      setExpectedInclusionTime(null);
      isLoadingRef.current = false;
    }
  };

  return { onClick, expectedInclusionTime };
}
