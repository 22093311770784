import { createSystemCalls } from "./createSystemCalls";
import { setupNetwork } from "./setupNetwork";

export type SetupResult = Awaited<ReturnType<typeof setup>>;

export async function setup() {
  const network = await setupNetwork();
  const systemCalls = createSystemCalls(network);

  console.log("Chain:", network.chain.name);
  console.log("World:", network.worldAddress);

  return {
    network,
    systemCalls,
  };
}
