import { SetupNetworkResult } from "./setupNetwork";
import {
  Account,
  Chain,
  Client,
  ContractFunctionArgs,
  ContractFunctionName,
  Transport,
} from "viem";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export type SystemCallsClient = Client<Transport, Chain, Account>; // TODO: Replace with { AppAccountClient } from "@latticexyz/account-kit"

export function createSystemCalls({
  publicClient,
  waitForTransaction,
  worldAddress,
}: SetupNetworkResult) {
  const spawn = async (
    client: SystemCallsClient,
    lineId: number,
    rightNeighbor: bigint,
    velRight: boolean
  ) => {
    return await armoredCall(client, "spawn", [lineId, rightNeighbor, velRight]);
  };

  const jumpToLine = async (client: SystemCallsClient, up: boolean) => {
    return await armoredCall(client, "jumpToLine", [up]);
  };

  const setDirection = async (client: SystemCallsClient, velRight: boolean) => {
    return await armoredCall(client, "setDirection", [velRight]);
  };

  const setUsername = async (client: SystemCallsClient, username: string) => {
    return await armoredCall(client, "setUsername", [username]);
  };

  async function armoredCall<
    functionName extends ContractFunctionName<typeof IWorldAbi, "nonpayable" | "payable">,
    args extends ContractFunctionArgs<typeof IWorldAbi, "nonpayable" | "payable", functionName>,
  >(client: SystemCallsClient, functionName: functionName, args: args) {
    try {
      // // @ts-ignore
      // const balanceBefore = await client.getBalance({ address: client.account?.address });

      // @ts-ignore
      const hash = await client.writeContract({
        address: worldAddress,
        abi: IWorldAbi,
        functionName,
        args,
        gas: 29_000_000n, // Can use a super high gas limit since the user won't see this anyway.
        // If you see a IntrinsicGasTooHighError coming from here, it means the gas limit is higher
        // than the block gas limit and needs to be turned down. Double-check what chain you're using.
      });

      console.log("Sent tx with hash:", hash);

      const res = await waitForTransaction(hash);

      // client
      //   // @ts-ignore
      //   .getBalance({ address: client.account?.address })
      //   .then((balanceAfter: bigint) => {
      //     console.log("Balance change:", (balanceAfter - balanceBefore).fromWad());
      //   });

      // TODO: Decode with https://viem.sh/docs/contract/decodeErrorResult#decodeerrorresult?
      if (res.status !== "success") {
        try {
          // @ts-ignore
          const transaction = await client.getTransaction({ hash });

          // @ts-ignore
          await client.call({
            blockNumber: transaction.blockNumber,
            data: transaction.input,
            to: transaction.to,
            value: transaction.value,
            gas: transaction.gas,
          });
        } catch (e: any) {
          console.error(e.toString());
          throw new Error(e?.shortMessage ?? e.toString());
        }

        console.warn("Transaction failed, but post-hoc simulation succeeded...");
        throw new Error(`${functionName} reverted: ${JSON.stringify(res)}`);
      }

      return res;
    } catch (e: any) {
      console.error(e.toString());
      if (e.toString().includes("does not have enough funds")) {
        throw new Error(
          "Your burner wallet does not have enough ETH to pay for gas. Top up its balance by clicking on the account button in the top right."
        );
      }
      throw e;
    }
  }

  return {
    spawn,
    jumpToLine,
    setDirection,
    setUsername,
  };
}
