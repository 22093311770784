import { WAD } from "./bigint";

export let CHAIN_TIMESTAMP_OFFSET_MS = { value: Number.MIN_SAFE_INTEGER };

// chain time is offset by however many ms from the turn of the second
// the genesis block of the chain was mined at, as block timestamp is
// rounded down to seconds. also accounts for device clock being off.
export function chainTime(): number {
  return Date.now() + CHAIN_TIMESTAMP_OFFSET_MS.value;
}

// seconds since epoch * 1e18
export function timeWad(): bigint {
  return msToWad(chainTime());
}

export function msToWad(ms: number): bigint {
  return (BigInt(ms) * WAD) / 1000n;
}

export function formatOffset(offset: number): string {
  return (offset >= 0 ? "+" : "") + offset.toFixed(2) + "ms";
}
