import { getBurnerPrivateKey } from "@latticexyz/common";
import { transactionQueue } from "@latticexyz/common/actions";
export { transactionQueue }; // For node.

/*
 * Import the addresses of the World, possibly on multiple chains,
 * from packages/contracts/worlds.json. When the contracts package
 * deploys a new `World`, it updates this file.
 */
import worlds from "contracts/worlds.json";

import { supportedChains } from "./supportedChains";

import { Hex } from "viem";

const params =
  typeof window !== "undefined"
    ? new URLSearchParams(window.location.search)
    : new URLSearchParams();

/*
 * The chain ID is the first item available from this list:
 * 1. chainId query parameter
 * 2. chainid query parameter
 * 3. The VITE_CHAIN_ID environment variable set when the
 *    vite dev server was started or client was built
 * 4. The default, 31337 (anvil)
 */
export const CHAIN_ID = Number(
  params.get("chainId") ||
    params.get("chainid") ||
    (typeof process !== "undefined" ? process.env.CHAIN_ID : import.meta.env.VITE_CHAIN_ID) ||
    31337
);

export function getNetworkConfig() {
  const chainIndex = supportedChains.findIndex((c) => c.id === CHAIN_ID);
  const chain = supportedChains[chainIndex];
  if (!chain) {
    throw new Error(`Chain ${CHAIN_ID} not found`);
  }

  /*
   * Get the address of the World. If you want to use a
   * different address than the one in worlds.json,
   * provide it as worldAddress in the query string.
   */
  const world = worlds[chain.id.toString()];
  const worldAddress = params.get("worldAddress") || world?.address;
  if (!worldAddress) {
    throw new Error(`No world address found for chain ${CHAIN_ID}. Did you run \`mud deploy\`?`);
  }

  /*
   * MUD clients use events to synchronize the database, meaning
   * they need to look as far back as when the World was started.
   * The block number for the World start can be specified either
   * on the URL (as initialBlockNumber) or in the worlds.json
   * file. If neither has it, it starts at the first block, zero.
   */
  const initialBlockNumber = params.has("initialBlockNumber")
    ? Number(params.get("initialBlockNumber"))
    : world?.blockNumber ?? 0n;

  return {
    privateKey: getBurnerPrivateKey(),
    chainId: CHAIN_ID,
    chain,
    worldAddress: worldAddress as Hex,
    initialBlockNumber,
  };
}
