import { DEBUG_VERBOSE, mapEntityToEmoji, findOrThrow } from "../debugging";
import {
  Entity,
  EntityType,
  computeX,
  isPoweredUp,
  leftmostEntityId,
  onConsumeEntity,
  rightmostEntityId,
} from "./entityLib";
import { PQ96x160 } from "../pq96x160";
import { scheduleCollision, insertEntityIntoLine } from "./lineLib";
import { GameConfig, GameState, mapMassToDiameter } from "./configLib";

export function removeEntityFromLine(
  line: Entity[],
  entity: Entity,
  collisionQueue: PQ96x160,
  globalVelCoeff: bigint
) {
  // Remove the entity from the line.  We don't have to do this
  // on-chain, as we just change the mapped line number there.
  line.splice(
    line.findIndex((e) => e.entityId == entity.entityId),
    1
  );

  if (entity.etype === EntityType.DEAD) throw new Error("ENTITY_IS_DEAD");

  // Get the entity's left and right neighbors.
  const leftNeighbor = findOrThrow(line, entity.leftNeighbor);
  const rightNeighbor = findOrThrow(line, entity.rightNeighbor);

  if (DEBUG_VERBOSE != null)
    console.log(
      "Removing entity",
      mapEntityToEmoji(entity.entityId),
      "from line",
      line[0].lineId,
      "- Updated neighbors",
      mapEntityToEmoji(leftNeighbor.entityId),
      "and",
      mapEntityToEmoji(rightNeighbor.entityId)
    );

  // Update the neighbors' references to skip the entity.
  leftNeighbor.rightNeighbor = rightNeighbor.entityId;
  rightNeighbor.leftNeighbor = leftNeighbor.entityId;

  // Schedule entity's right neighbor's collision with its new left neighbor.
  scheduleCollision(leftNeighbor, rightNeighbor, collisionQueue, globalVelCoeff);
}

export function insertExistingEntityIntoLine(
  line: Entity[],
  entity: Entity,
  wadTime: bigint,
  collisionQueue: PQ96x160,
  gameState: GameState,
  gameConfig: GameConfig,
  playSfx: boolean,
  playerIdForSfx: bigint | null
) {
  const lineId = line[0].lineId;

  let [entityLeftEdge, entityMass] = [
    computeX(entity, wadTime, gameConfig.velocityCoefficient),
    entity.mass,
  ];
  let entityDiameter = mapMassToDiameter(entityMass);

  // Start the right neighbor search at the right neighbor of the leftmost entity.
  let rightNeighbor = findOrThrow(line, findOrThrow(line, leftmostEntityId(lineId)).rightNeighbor);
  let rightNeighborLeftEdge = computeX(rightNeighbor, wadTime, gameConfig.velocityCoefficient);

  // Search for the closest right neighbor without overlap.
  while (entityLeftEdge + entityDiameter >= rightNeighborLeftEdge) {
    if (rightNeighbor.entityId === rightmostEntityId(lineId))
      throw new Error("NO_VALID_RIGHT_NEIGHBOR"); // Just in case.
    rightNeighborLeftEdge = computeX(
      (rightNeighbor = findOrThrow(line, rightNeighbor.rightNeighbor)),
      wadTime,
      gameConfig.velocityCoefficient
    );
  }

  // Start the left neighbor search at the left neighbor of the selected right neighbor.
  let leftNeighbor = findOrThrow(line, rightNeighbor.leftNeighbor);
  let leftNeighborRightEdge =
    computeX(leftNeighbor, wadTime, gameConfig.velocityCoefficient) +
    mapMassToDiameter(leftNeighbor.mass);

  // Search for a left neighbor without overlap, consuming all with overlap.
  while (leftNeighborRightEdge >= entityLeftEdge) {
    if (leftNeighbor.entityId === leftmostEntityId(lineId))
      throw new Error("NO_VALID_LEFT_NEIGHBOR"); // Just in case.

    // Determine whether the entity can consume the overlapping left neighbor candidate.
    let [leftNeighborMass, leftNeighborType, isLeftNeighborPoweredUp] = [
      leftNeighbor.mass,
      leftNeighbor.etype,
      isPoweredUp(leftNeighbor, wadTime, gameConfig.powerPelletEffectTime),
    ];
    if (
      !(
        // prettier-ignore
        // 1. Non-{food, power-pellet} entity wins over food/power-pellet
        // 2. Powered-up entity wins (if both are powered up, neither wins)
        // 3. Entity with greater mass wins
        (leftNeighborType != EntityType.WALL
        && (leftNeighborType == EntityType.FOOD
        || leftNeighborType == EntityType.POWER_PELLET
        || (isPoweredUp(entity, wadTime, gameConfig.powerPelletEffectTime) && !isLeftNeighborPoweredUp)
        || (entityMass > leftNeighborMass && !isLeftNeighborPoweredUp)))
      )
    )
      throw new Error("WOULD_OVERLAP_WITH_UNCONSUMABLE_ENTITY"); // Revert if the candidate is unconsumable.

    // To discourage players from rapidly spawning bots to feed themselves, we cap the mass you gain
    // from consuming a player to min(consumedEntityMass, Player.getConsumedMass(consumedEntity)).
    if (leftNeighborType === EntityType.ALIVE)
      leftNeighborMass = leftNeighborMass // Conceptually equal to consumedEntityMass.
        .min(leftNeighbor.consumedMass); // consumedEntity = leftNeighbor.

    const consumedEntity = leftNeighbor; // Need to cache as we'll reassign leftNeighbor below.
    leftNeighbor = findOrThrow(line, consumedEntity.leftNeighbor); // Try another neighbor to the left.
    // Must go after finding the new left neighbor, this'll remove the consumedEntity from the line.
    onConsumeEntity(
      line,
      gameState,
      gameConfig,
      entity,
      consumedEntity,
      wadTime,
      playSfx,
      playerIdForSfx
    );

    // Retrieve and update the right edge of the new left neighbor candidate. Checked for overlap in the while.
    leftNeighborRightEdge =
      computeX(leftNeighbor, wadTime, gameConfig.velocityCoefficient) +
      mapMassToDiameter(leftNeighbor.mass);

    // Update the entity's mass and diameter (uncommitted) given it just absorbed the left neighbor. We also
    // update the left edge of the entity to account for its larger diameter, see inline comments for more.
    const newDiameter = mapMassToDiameter((entityMass += leftNeighborMass));
    entityLeftEdge -= newDiameter - entityDiameter; // See winnerEntityNewLastX in processCollisions for why.
    entityDiameter = newDiameter; // This is done last because the line above depends on the old entityDiameter.
  }

  // Apply the, until now, uncommitted updates to mass (and thus diameter).
  const massConsumed = entityMass - entity.mass;
  entity.mass = entityMass;
  entity.consumedMass += massConsumed;

  insertEntityIntoLine(
    line,
    entity,
    entityLeftEdge,
    leftNeighbor,
    rightNeighbor,
    wadTime,
    collisionQueue,
    gameConfig.velocityCoefficient
  );
}
